import React from "react";

export default function Aboutus() {
  return (
    <>
      <section
        id="content"
        className="container clearfix"
        style={{
          padding: "0px",
          outline: "none",
          margin: "0px auto",
          width: "940px",
          marginBottom: "0px",
          minHeight: "1001px",
        }}
      >
        {" "}
        <header
          id="page-header"
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "40px",
          }}
        >
          {" "}
          <h1
            id="page-title"
            style={{
              margin: "0px",
              outline: "none",
              marginBottom: "15px",
              fontFamily:
                'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", sans-serif',
              fontWeight: 300,
              fontSize: "2.333em",
              lineHeight: "1.214em",
              padding: "10px 0px",
              borderBottom: "1px solid rgb(229, 229, 229)",
            }}
          >
            About Us
          </h1>{" "}
        </header>{" "}
        <div
          className="intro"
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            fontWeight: 600,
            fontSize: "1.083em",
            marginTop: "-20px",
          }}
        >
          <span
            className="style1"
            style={{ margin: "0px", padding: "0px", outline: "none" }}
          >
            <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Our Vision
            </strong>
          </span>
        </div>
        <div
          className="intro"
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            fontWeight: 600,
            fontSize: "1.083em",
            marginTop: "20px",
          }}
        >
          To provide affordable, robust, and durable enterprise solutions.
        </div>
        <div
          className="intro"
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            fontWeight: 600,
            fontSize: "1.083em",
            marginTop: "20px",
          }}
        >
          <span
            className="style1"
            style={{ margin: "0px", padding: "0px", outline: "none" }}
          >
            <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Our Mission
            </strong>
          </span>
        </div>
        <div
          className="intro"
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            fontWeight: 600,
            fontSize: "1.083em",
            marginTop: "20px",
          }}
        >
          To enhance and innovate software applications, providing value and
          profitability by reaching and exceeding beyond expectations.
        </div>
        <div
          className="intro"
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            fontWeight: 600,
            fontSize: "1.083em",
            marginTop: "20px",
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Our Values
          </strong>
        </div>
        <div
          className="intro"
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            fontWeight: 600,
            fontSize: "1.083em",
            marginTop: "20px",
          }}
        >
          <p
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "20px",
            }}
          >
            We value relationships, friendship, confidence, goals, profitable
            results, taking ownership and working above expectations.
          </p>
        </div>
        <section
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "40px",
          }}
        >
          <h2
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "15px",
              fontFamily:
                'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", sans-serif',
              fontWeight: "bold",
              fontSize: "1.5em",
              lineHeight: "1.333em",
            }}
          >
            Our Management Team
          </h2>
          <table
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            style={{ margin: "0px", padding: "0px", outline: "none" }}
          >
            <tbody style={{ margin: "0px", padding: "0px", outline: "none" }}>
              <tr style={{ margin: "0px", padding: "0px", outline: "none" }}>
                <td
                  width="81%"
                  style={{ margin: "0px", padding: "0px", outline: "none" }}
                >
                  <h4
                    style={{
                      margin: "0px",
                      padding: "0px",
                      outline: "none",
                      marginBottom: "15px",
                      fontFamily:
                        'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", sans-serif',
                      fontWeight: 600,
                      fontSize: "1.166em",
                      lineHeight: "1.428em",
                    }}
                  >
                    Evan Parissis - Director
                  </h4>
                  <p
                    style={{
                      margin: "0px",
                      padding: "0px",
                      outline: "none",
                      marginBottom: "20px",
                    }}
                  >
                    Evan has over 25 years of experience in computing, and is
                    passionate about innovation and new technologies.
                    Multilingual and multicultural, with a keen attention to
                    detail, Evan has implemented first class projects in a
                    diversified environment for companies such as Duke Energy,
                    First Union Bank, Microsoft. He has developed patented
                    software for E I Dupont De Nemours, and while implemented
                    applications at Glen Raven’s facility was voted the most
                    advanced textile plant in the Millennium.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            style={{ margin: "0px", padding: "0px", outline: "none" }}
          >
            <tbody style={{ margin: "0px", padding: "0px", outline: "none" }}>
              <tr style={{ margin: "0px", padding: "0px", outline: "none" }}>
                <td
                  width="81%"
                  style={{ margin: "0px", padding: "0px", outline: "none" }}
                >
                  <h4
                    style={{
                      margin: "0px",
                      padding: "0px",
                      outline: "none",
                      marginBottom: "15px",
                      fontFamily:
                        'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", sans-serif',
                      fontWeight: 600,
                      fontSize: "1.166em",
                      lineHeight: "1.428em",
                    }}
                  >
                    Vicky Vasquez - Office Manager
                  </h4>
                  <p
                    style={{
                      margin: "0px",
                      padding: "0px",
                      outline: "none",
                      marginBottom: "20px",
                    }}
                  >
                    Vicky offers exceptional skills in the office daily running
                    tasks while she is deeply involved in computing and user
                    operated interactions. Multilingual, she enjoys the
                    challenges of communication and human interaction and is an
                    enthusiast of technology and innovation. She researches
                    current and future technological niche achievements. Vicky
                    is highly experienced in communications and training and has
                    provided operational and human resources management for
                    large institutions.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </section>
    </>
  );
}
