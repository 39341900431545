import Navbar from "./Navbar";
import { Container, Row, Col } from "react-bootstrap";
export default function Header() {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Navbar />
        </Col>
      </Row>
    </Container>
  );
}
