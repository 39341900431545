import React from "react";
import PageHeader from "./PageHeader";

export default function Profile() {
  return (
    <>
      <section
        id="content"
        className="container clearfix"
        style={{
          padding: "0px",
          outline: "none",
          margin: "0px auto",
          marginBottom: "0px",
        }}
      >
        <PageHeader>{"Profile"}</PageHeader>
        <section
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "40px",
          }}
        >
          {" "}
          <div
            className="one column"
            style={{ margin: "0px", padding: "0px", outline: "none" }}
          ></div>
        </section>
      </section>
    </>
  );
}
