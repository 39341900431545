import React from "react";

export default function Component() {
  return (
    <>
      <section id="content" className="container clearfix">
        <header
          id="page-header"
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "40px",
          }}
        >
          <h1
            id="page-title"
            style={{
              margin: "0px",
              outline: "none",
              marginBottom: "15px",
              fontFamily:
                'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", sans-serif',
              fontWeight: 300,
              fontSize: "2.333em",
              lineHeight: "1.214em",
              padding: "10px 0px",
              borderBottom: "1px solid rgb(229, 229, 229)",
            }}
          >
            Locator®
          </h1>
        </header>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
            fontSize: "14px",
          }}
        >
          <img
            height={66}
            width={280}
            alt="Smart Locator® ERP"
            src="https://www.attikas.com/wp-content/uploads/2022/11/RegisteredLocator.jpg"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              display: "block",
              border: "1px solid rgb(221, 221, 221)",
              marginRight: "160px",
            }}
          />
          Attika System is powered by Smart Locator® a business solution for a
          wide spectrum of enterprises. It can operate in stand alone services
          to better serve the specific business capability and needs. While its
          expandable and flexible modularity allows to address multi enterprise
          functions as business grows. It is a scalable solution to sustain high
          workloads with predictable performance. It is operating system and
          cloud platform agnostic; while we can interface with all major
          operating systems and cloud providers. Gaining in business
          productivity by quick deployment with zero loss of productivity.
        </p>
        <hr
          style={{
            padding: "0px",
            outline: "none",
            border: "none",
            margin: "40px 0px",
            backgroundColor: "rgb(229, 229, 229)",
            color: "rgb(229, 229, 229)",
            height: "1px",
            clear: "both",
          }}
        />
      </section>
    </>
  );
}
