import {
  FaFacebook,
  FaLinkedinIn,
  FaXTwitter,
  FaPhone,
  FaEnvelope,
} from "react-icons/fa6";

export default function Footer() {
  return (
    <footer className="sticky-footer text-center  mt-auto">
      <div className="Footer-color">
        <div className="container p-2">
          <div className="row">
            <h6 className="mb-0">This is a prototype website.</h6>
            <div className="col-lg-2">
              <h5 className="col-lg-16">Attika Solutions Ltd</h5>
              <p>
                29 Cooke Rise, Warfield
                <br />
                Bracknell, United Kingdom
                <a
                  href="https://www.facebook.com/attikasolutions"
                  alt="+44 1344306961"
                >
                  <i>
                    <FaPhone size={"1em"} color={"cccccc"} />
                  </i>
                </a>{" "}
                +44 1344306961
                <br />
                <a
                  href="https://www.facebook.com/attikasolutions"
                  alt="info@attikas.com"
                >
                  <i>
                    <FaEnvelope size={"1em"} color={"cccccc"} />
                  </i>
                </a>{" "}
                info@attikas.com
              </p>
              <ul className="horizontalNoBullets">
                <li>
                  <a
                    href="https://www.facebook.com/attikasolutions"
                    alt="facebook"
                  >
                    <i>
                      <FaFacebook size={"1.5em"} color={"cccccc"} />
                    </i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/attikasolutions"
                    alt="linkedin"
                  >
                    <i>
                      <FaLinkedinIn size={"1.5em"} color={"cccccc"} />
                    </i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.twitter.com/attikasolutions"
                    alt="twitter"
                  >
                    <FaXTwitter size={"1.5em"} color={"cccccc"} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="text-center p-2" style={{ color: "white" }}>
          <span className="text-center p-2">
            © 2024 Attika Solutions Ltd &nbsp; &nbsp;
            <a
              href="/PrivacyPolicy"
              alt="Privacy Policy"
              style={{ color: "white" }}
            >
              Privacy Policy
            </a>
            <a
              href="/TermsandConditions"
              alt="Terms & Conditions"
              style={{ color: "white" }}
            >
              &nbsp;|&nbsp;Terms & Conditions
            </a>
            <a href="/Slavery" alt="License" style={{ color: "white" }}>
              &nbsp;|&nbsp;Slavery
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
}
