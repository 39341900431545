import { useContext, useState } from "react";
import { userContext } from "./contexts/UserContext";
export default function Signin() {
  const [value, setValue] = useState("");
  const { dispatch } = useContext(userContext);
  function handleSubmit(e) {
    e.preventDefault();
    dispatch({
      type: "signin",
      payload: { username: { value }, isAuthenticated: true },
    });
  }
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type="text"
          placeholder="Enter your username"
          required="required"
          pattern="(?=.*[a-z])(?=.*[A-Z]).{8,}"
          title="Must contain at least one uppercase and lowercase letter, and at least 8 or more characters"
        />
        <button type="submit">Signin</button>
      </form>
    </div>
  );
}
// pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
// title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
