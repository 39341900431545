import React from "react";

export default function Slavery() {
  return (
    <>
      <section
        id="content"
        className="container clearfix"
        style={{
          padding: "0px",
          outline: "none",
          margin: "0px auto",
          width: "940px",
          marginBottom: "0px",
          minHeight: "996px",
        }}
      >
        {" "}
        <header
          id="page-header"
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "40px",
          }}
        >
          {" "}
          <h1
            id="page-title"
            style={{
              margin: "0px",
              outline: "none",
              marginBottom: "15px",
              fontFamily:
                'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", sans-serif',
              fontWeight: 300,
              fontSize: "2.333em",
              lineHeight: "1.214em",
              padding: "10px 0px",
              borderBottom: "1px solid rgb(229, 229, 229)",
            }}
          >
            Slavery and Human Trafficking
          </h1>{" "}
        </header>{" "}
        <section
          id="main"
          className="three-fourths"
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            display: "inline",
            cssFloat: "left",
            marginRight: "2%",
            width: "74.5%",
            maxWidth: "700px",
            marginBottom: "0px",
          }}
        >
          {" "}
          <h3
            className="wp-block-heading"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "15px",
              fontFamily:
                'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", sans-serif',
              fontWeight: 600,
              fontSize: "1.333em",
              lineHeight: "1.375em",
            }}
          >
            <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Pursuant to UK Modern Slavery Act or 2019
            </strong>
          </h3>
          <p
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "20px",
            }}
          >
            <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
              MODERN SLAVERY AND HUMAN TRAFFICKING STATEMENT 
            </strong>
          </p>
          <h4
            className="wp-block-heading"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "15px",
              fontFamily:
                'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", sans-serif',
              fontWeight: 600,
              fontSize: "1.166em",
              lineHeight: "1.428em",
            }}
          >
            <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Introduction 
            </strong>
             
          </h4>
          <p
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "20px",
            }}
          >
            Modern slavery is a heinous crime and a morally reprehensible act
            that deprives a person's liberty and dignity for another person's
            gain. At Attika Solutions Ltd, we have a zero-tolerance approach to
            modern slavery and are fully committed to preventing slavery and
            human trafficking in our operation and supply chain. We have taken
            concrete steps to tackle modern slavery, as outlined in our
            statement. This statement sets out the actions that we have taken to
            understand all potential modern slavery risks related to our
            business, and to implement steps to prevent slavery and human
            trafficking during the financial year 2020.
          </p>
          <h4
            className="wp-block-heading"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "15px",
              fontFamily:
                'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", sans-serif',
              fontWeight: 600,
              fontSize: "1.166em",
              lineHeight: "1.428em",
            }}
          >
            <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Our business 
            </strong>
          </h4>
          <div
            className="wp-block-group"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              boxSizing: "border-box",
            }}
          >
            <div
              className="wp-block-group__inner-container is-layout-flow"
              style={{ margin: "0px", padding: "0px", outline: "none" }}
            >
              <p
                style={{
                  margin: "0px",
                  padding: "0px",
                  outline: "none",
                  marginBottom: "20px",
                }}
              >
                Provides:
              </p>
              <ul
                className="circle indent"
                style={{
                  margin: "0px",
                  padding: "0px",
                  outline: "none",
                  listStyle: "none",
                  boxSizing: "border-box",
                }}
              >
                <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
                  Software development services 
                </li>
                <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
                  Information Technology Specialists 
                </li>
                <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
                  Data Collection Equipment 
                </li>
              </ul>
              <p
                style={{
                  margin: "0px",
                  padding: "0px",
                  outline: "none",
                  marginBottom: "20px",
                }}
              >
                Operates:
              </p>
              <ul
                style={{
                  margin: "0px",
                  padding: "0px",
                  outline: "none",
                  listStyle: "none",
                  boxSizing: "border-box",
                }}
              >
                <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
                  In United Kingdom
                </li>
              </ul>
              <p
                style={{
                  margin: "0px",
                  padding: "0px",
                  outline: "none",
                  marginBottom: "20px",
                }}
              />
              <p
                style={{
                  margin: "0px",
                  padding: "0px",
                  outline: "none",
                  marginBottom: "20px",
                }}
              >
                <strong
                  style={{ margin: "0px", padding: "0px", outline: "none" }}
                >
                  Our supply chains 
                </strong>
              </p>
            </div>
          </div>
          <p
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "20px",
            }}
          >
            We establish a relationship of trust and integrity with all our
            suppliers, which is built upon mutually beneficial factors. Our
            supplier selection and on-boarding procedure includes due diligence
            of the supplier's reputation, respect for the law, compliance with
            health, safety and environmental standards, and references.  We
            haven't been made aware of any allegations of human
            trafficking/slavery activities against any of our suppliers, but if
            we were, then we would act immediately against the supplier and
            report it to the authorities.{" "}
          </p>
          <p
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "20px",
            }}
          >
            We act as the “main vendor”, and we will work with other vendors
            that provide workers to hiring companies. Some specialists might
            work via their limited companies. Others might be introduced to us
            from other businesses that accommodate such hiring. Some of the
            specialists might apply directly to our published adverts. All of
            the hired specialists are identified by our employees and apply are
            policies and legal requirements to ensure sound hiring processes.
          </p>
          <h4
            className="wp-block-heading"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "15px",
              fontFamily:
                'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", sans-serif',
              fontWeight: 600,
              fontSize: "1.166em",
              lineHeight: "1.428em",
            }}
          >
            <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Policies
            </strong>
              
          </h4>
          <p
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "20px",
            }}
          >
            Attika Solutions Ltd operates the following policies for identifying
            and preventing slavery and human trafficking in our operations:  
          </p>
          <ul
            id="block-967c9414-0023-41b3-b470-b01db5f674f1"
            className="circle indent"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              listStyle: "none",
              boxSizing: "border-box",
            }}
          >
            <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Anti-Bribery and Corruption Policy
            </li>
            <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Code of Conduct, Bullying and Harassment Policy
            </li>
            <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Equal Opportunities and Diversity Policy
            </li>
            <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Purchasing Code - Policy we have updated our Purchasing Code and
              supplier contracts to make explicit reference to slavery and human
              trafficking.
            </li>
            <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Whistleblowing Policy - we encourage all employees, customers and
              suppliers to report any suspicion of slavery or human trafficking
              without fear of retaliation. We provide a confidential helpline to
              protect the identity of the whistle blowers.
            </li>
          </ul>
          <h4
            className="wp-block-heading"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "15px",
              fontFamily:
                'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", sans-serif',
              fontWeight: 600,
              fontSize: "1.166em",
              lineHeight: "1.428em",
            }}
          >
            <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Policy Development and Review
            </strong>
             
          </h4>
          <p
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "20px",
            }}
          >
            Attika Solutions’ policies are established by our senior leadership
            team based on advice from Human Resource professionals, industry
            best practice and legal advice. We review our policies annually, or
            as needed to adapt to changes.
          </p>
          <h4
            className="wp-block-heading"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "15px",
              fontFamily:
                'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", sans-serif',
              fontWeight: 600,
              fontSize: "1.166em",
              lineHeight: "1.428em",
            }}
          >
            <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Awareness
            </strong>
              
          </h4>
          <p
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "20px",
            }}
          >
            Attika Solutions Ltd has raised awareness of modern slavery issues
            by putting up posters across our facilities and sending an email
            that is focused specifically on modern slavery to all our staff,
            which explains:  
          </p>
          <ul
            className="circle indent"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              listStyle: "none",
              boxSizing: "border-box",
            }}
          >
            <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Our commitment in the fight against modern slavery  
            </li>
            <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Red flags for potential cases of slavery or human trafficking  
            </li>
            <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
              How employees should report suspicions of modern slavery 
              <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
            </li>
          </ul>
          <h4
            className="wp-block-heading"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "15px",
              fontFamily:
                'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", sans-serif',
              fontWeight: 600,
              fontSize: "1.166em",
              lineHeight: "1.428em",
            }}
          >
            <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Managing Risk 
            </strong>
             
          </h4>
          <p
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "20px",
            }}
          >
            We ensure a high level of understanding of the risks of modern
            slavery and human trafficking in our supply chains and our business,
            we provide training to relevant members of staff. All Directors have
            been briefed on the subject. Our staff is encouraged to discuss any
            concerns that they have.
          </p>
          <p
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "20px",
            }}
          >
            We haven't been made aware of any allegations of human
            trafficking/slavery activities against any of our suppliers, but if
            we were, then we would act immediately against the supplier and
            report it to the authorities.
          </p>
          <p
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "20px",
            }}
          >
            This statement covers 31 April 2020 and has been approved by the
            board of Attika Solutions Ltd at the board meeting on 01 May 2021. 
          </p>
          <p
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "20px",
            }}
          >
            Evan Parissis 
          </p>
          <p
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "20px",
            }}
          >
            <sup style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Director
            </sup>
             
          </p>
          <p
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              marginBottom: "20px",
            }}
          >
            01 January 2024 
          </p>{" "}
        </section>{" "}
      </section>
    </>
  );
}
