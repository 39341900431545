import React from "react";
import PageHeader from "./PageHeader";
import GridCards2 from "./GridCards2";
import HTabs from "./HTAbs";

export default function MTD() {
  return (
    <>
      <section
        id="content"
        className="container clearfix"
        style={{
          padding: "0px",
          outline: "none",
          margin: "0px auto",
          marginBottom: "0px",
        }}
      >
        <PageHeader>{"MTD"}</PageHeader>
        <section
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "40px",
          }}
        >
          {" "}
          <div
            className="one column"
            style={{ margin: "0px", padding: "0px", outline: "none" }}
          >
            {" "}
            <div
              style={{
                margin: "0px",
                padding: "0px",
                outline: "none",
                cssFloat: "right",
                marginLeft: "15px",
              }}
            />
            <p
              style={{
                margin: "0px",
                padding: "0px",
                outline: "none",
                marginBottom: "20px",
              }}
            />
            <h3
              style={{
                margin: "0px",
                padding: "0px",
                outline: "none",
                marginBottom: "15px",
                fontFamily:
                  'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", sans-serif',
                fontWeight: 600,
                fontSize: "1.333em",
                lineHeight: "1.375em",
              }}
            >
              More unified, yet, independent business processes.{" "}
            </h3>{" "}
            <div style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Today, your business has more processes than ever before. Getting
              them to work together can be complex. At Attika Solutions, our
              service oriented architecture solutions are geared towards helping
              your business processes work together seamlessly.{" "}
              <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
              <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
              We begin thorough analysis, breaking down each process into its
              component parts. We understand and look for ways to optimise each
              and every protocol and increase process independence, reducing the
              need for dependence on other process in order for it to work.{" "}
              <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
              <br
                style={{ margin: "0px", padding: "0px", outline: "none" }}
              />{" "}
              <strong
                style={{ margin: "0px", padding: "0px", outline: "none" }}
              >
                Our microservice architecture solutions make your processes:{" "}
              </strong>{" "}
              <ul
                className="circle indent"
                style={{
                  margin: "0px",
                  padding: "0px",
                  outline: "none",
                  listStyle: "none",
                  boxSizing: "border-box",
                }}
              >
                <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
                  More manageable and coherent.{" "}
                </li>
                <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
                  Work more efficiently with each process running on its own.
                  Independently.{" "}
                </li>{" "}
                <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
                  More resilient. If there is a fault with one process, the
                  others can continue.{" "}
                </li>
                <li style={{ margin: "0px", padding: "0px", outline: "none" }}>
                  Easy migration from an Enterprise Service Bus that works on
                  canonical data representation of dumb endpoints with smart
                  pipes to microservices of dumb pipes with smart endpoints.
                </li>
              </ul>
            </div>{" "}
            To talk to us about your microservice architecture requirements,{" "}
            <a
              href="/contactus"
              title="Contact"
              style={{
                margin: "0px",
                padding: "0px",
                outline: "none",
                textDecoration: "none",
                transition:
                  "color 0.2s, background-color 0.2s, border 0.2s, opacity 0.2s ease-in-out",
                color: "rgb(72, 123, 206)",
              }}
            >
              Contact us
            </a>{" "}
            today.
          </div>
        </section>
      </section>
      <GridCards2></GridCards2>
      <HTabs></HTabs>
    </>
  );
}
