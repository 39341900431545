//import React, { createContext, useContext, useState }  from 'react';
import React from 'react';
import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import { UserProvider } from "./contexts/UserContext";

function App() {
  return (
    <div className="d-flex flex-column min-vh-100">
      <UserProvider>
        <Header />
        <Footer />     
      </UserProvider>
    </div>
  );
      
}

export default App;
