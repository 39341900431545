import Stack from "react-bootstrap/Stack";
// import BasicCard from "./BasicCard";
import GridCards from "./GridCards";
import Carousel from "./MainPresenter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function HomePage() {
  return (
    <div className="container">
      <Carousel></Carousel>
      <Container>
        <Row>
          <Col>1 of 2</Col>
          <Col>2 of 2</Col>
        </Row>
        <Row>
          <Col>1 of 3</Col>
          <Col>2 of 3</Col>
          <Col>3 of 3</Col>
        </Row>
      </Container>
      <div className="container p-4">
        <div className="row">
          <Stack gap={1}>
            <div className="p-8">
              <h5>Office Automation</h5>
            </div>
            <div className="p-8">Second item</div>
          </Stack>
        </div>
      </div>
      <div className="container p-4">
        <div className="row">
          <div className="col">
            <h5 className="mb-0">MTD and more</h5>
            <ul className="list-unstyled mb-0">
              <li>
                <GridCards></GridCards>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
