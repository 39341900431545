import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

const data = [
  {
    key: 1,
    image: require("./assets/banner1-940x350.jpg"),
    caption: "Software. Development. Innovation.",
    description:
      "Custom-made software development - bespoke, intelligent and with the power to transform your business.",
  },
  {
    key: 2,
    image: require("./assets/banner2-940x350.jpg"),
    caption: "Tomorrow's technology. Today.",
    description:
      "Groundbreaking software development using the very latest web, cloud and business intelligence technology.",
  },
  {
    key: 3,
    image: require("./assets/banner3-940x350.jpg"),
    caption: "Software Solutions? No problem.",
    description:
      "We're problem solvers. Our software solutions provide the answers to your key business questions.",
  },
];
function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} className="carousel">
      {data.map((slide, i) => {
        return (
          <Carousel.Item key={i}>
            <img
              className="d-block w-100"
              src={slide.image}
              alt="slider banner"
            />
            <Carousel.Caption>
              <h3>{slide.caption}</h3>
              <p>{slide.description}</p>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default ControlledCarousel;
