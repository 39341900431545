import React from "react";
import { FaUserTie } from "react-icons/fa6";

const GridCards2 = () => {
  const servicesData = [
    {
      icon: <FaUserTie size={40} className="text-primary mb-4" />,
      title: "File your VAT",
      description:
        "You can file your VAT to HMRC directly from your spreadsheet editor. You can manage your VAT fillings with just a few clicks. You do not have to switch to other applications while you are in charge of your data.",
      link: "/vat",
    },
    {
      icon: <FaUserTie size={40} className="text-primary mb-4" />,
      title: "File your Income Tax Self Assesment",
      description:
        "In 2026 HMRC will mandate sole traders and landlords to file their income using MTD ITSA.",
      link: "/itsa",
    },
  ];
  /*
    image: require("./assets/image10.jpg"),
    <Image src={service.image} thumbnail></Image>
    */
  return (
    <React.Fragment>
      <div className="py-5">
        <div className="container">
          <div className="row g-4">
            {servicesData.map((service, index) => (
              <div
                key={index}
                className={`col-lg-6 col-sm-6 wow fadeInUp`}
                data-wow-delay={`${0.1 + index * 0.2}s`}
              >
                <div className="service-item rounded pt-3">
                  <div className="p-4">
                    <h5>{service.title}</h5>
                    <p>{service.description}</p>

                    <a href={service.link}>read more</a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GridCards2;
