import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Link,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavLink from "react-bootstrap/NavLink";
import "bootstrap/dist/css/bootstrap.min.css";
import AttikaSystem from "./AttikaSystem";
import Aboutus from "./Aboutus";
import HomePage from "./HomePage";
import ITSA from "./ITSA";
import Locator from "./Locator";
import MTD from "./MTD";
import Pricing from "./Pricing";
import PrivacyPolicy from "./PrivacyPolicy";
import Profile from "./Profile";
import Register from "./Register";
import Signin from "./Signin";
import Signout from "./Signout";
import Slavery from "./Slavery";
import Support from "./Support";
import TermsandConditions from "./TermsandConditions";
import VAT from "./VAT";
import { userContext } from "./contexts/UserContext";

function NotFound() {
  return (
    <div>
      <h2>404 Not Found</h2>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
}

const NavBar = ({ children }) => {
  const { user } = useContext(userContext);

  return (
    <Router>
      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <h5 className="mb-0">Attika Solutions</h5>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <NavLink as={Link} to="/mtd">
                Making Tax Digital
              </NavLink>
              <NavLink as={Link} to="/locator">
                Locator
              </NavLink>
              <NavLink as={Link} to="/pricing">
                Pricing
              </NavLink>
              <Nav.Link as={Link} to="/support">
                Support
              </Nav.Link>
            </Nav>
            <Nav>
              <NavLink as={Link} to="/aboutus">
                About us
              </NavLink>
              {user.isAuthenticated ? (
                false
              ) : (
                <NavLink as={Link} to="/register">
                  Register
                </NavLink>
              )}
              <NavLink as={Link} to="/profile" className="NavLinkSignout">
                {user.username.value}
              </NavLink>
              <Nav.Link eventKey={2} href="#signin">
                {user.isAuthenticated ? (
                  <Signout username={user.username.value} />
                ) : (
                  <Signin />
                )}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="container mt-4">
        <Routes>
          <Route path="/" element={<Outlet />}>
            <Route index element={<HomePage />} />
            <Route path="/AttikaSystem" element={<AttikaSystem />} />
            <Route path="/Aboutus" element={<Aboutus />} />
            <Route path="/ITSA" element={<ITSA />} />
            <Route path="/Locator" element={<Locator />} />
            <Route path="/MTD" element={<MTD />} />
            <Route path="/Pricing" element={<Pricing />} />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/Profile" element={<Profile />} />{" "}
            <Route path="/Register" element={<Register />} />
            <Route path="/Slavery" element={<Slavery />} />
            <Route path="/Support" element={<Support />} />
            <Route
              path="/TermsandConditions"
              element={<TermsandConditions />}
            />
            <Route path="/VAT" element={<VAT />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default NavBar;
