import { useContext } from "react";
import { userContext } from "./contexts/UserContext";

export default function Signout({ username }) {
  const { dispatch } = useContext(userContext);

  return (
    <div>
      <button
        className="signout"
        onClick={() =>
          dispatch({
            type: "signout",
            payload: { username: "", isAuthenticated: false },
          })
        }
      >
        <u>Sign out</u>
      </button>
    </div>
  );
}
