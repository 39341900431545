import React from "react";
import { FaCheck } from "react-icons/fa6";
import PageHeader from "./PageHeader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Pricing() {
  return (
    <>
      <PageHeader>Pricing</PageHeader>
      <Container>
        <Row md={4}>
          <Col></Col>
          <Col xs={6}>
            <strong font-size={"2.5em"}>Startup Free</strong>
          </Col>
          <Col>
            <strong>Company</strong>
          </Col>
          <Col>
            <strong>Accountant</strong>
          </Col>
        </Row>
        <Row md={4}>
          <Col>
            <strong>Single License</strong>
          </Col>
          <Col xs={6}>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
        </Row>
        <Row md={4}>
          <Col>
            <strong>Single Client</strong>
          </Col>
          <Col xs={6}>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
        </Row>
        <Row md={4}>
          <Col>
            <strong>-</strong>
          </Col>
          <Col xs={6}></Col>
          <Col></Col>
          <Col></Col>
        </Row>
        <Row md={4}>
          <Col>
            <strong>Addon Installation</strong>
          </Col>
          <Col xs={6}>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
        </Row>
        <Row md={4}>
          <Col>
            <strong>- M365 Excel Addon</strong>
          </Col>
          <Col xs={6}>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
        </Row>
        <Row md={4}>
          <Col>
            <strong>- Google Sheets Addon</strong>
          </Col>
          <Col xs={6}>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
        </Row>
        <Row md={4}>
          <Col>
            <strong>-</strong>
          </Col>
          <Col xs={6}></Col>
          <Col></Col>
          <Col></Col>
        </Row>
        <Row md={4}>
          <Col>
            <strong>No Addon Installation</strong>
          </Col>
          <Col xs={6}></Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
        </Row>
        <Row md={4}>
          <Col>
            <strong>- Web access</strong>
          </Col>
          <Col xs={6}></Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
        </Row>
        <Row md={4}>
          <Col>
            <strong>- Import Spread Sheet</strong>
          </Col>
          <Col xs={6}> </Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
        </Row>
        <Row md={4}>
          <Col>
            <strong>- Invoicing</strong>
          </Col>
          <Col xs={6}></Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
        </Row>
        <Row md={4}>
          <Col>
            <strong>- Expenses Tracking</strong>
          </Col>
          <Col xs={6}></Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
        </Row>
        <Row md={4}>
          <Col>
            <strong>- Mobile App</strong>
          </Col>
          <Col xs={6}></Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
          <Col> </Col>
        </Row>
        <Row md={4}>
          <Col>
            <strong>-</strong>
          </Col>
          <Col xs={6}></Col>
          <Col></Col>
          <Col></Col>
        </Row>
        <Row md={4}>
          <Col>
            <strong>Multiple Licenses</strong>
          </Col>
          <Col xs={6}></Col>
          <Col></Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
        </Row>
        <Row md={4}>
          <Col>
            <strong>Multiple Companies</strong>
          </Col>
          <Col xs={6}></Col>
          <Col></Col>
          <Col>
            <FaCheck size={"1.4em"} color={"000000"} />
          </Col>
        </Row>
      </Container>
    </>
  );
}
