import React from "react";

export default function PageHeader({ children }) {
  return (
    <section
      id="content"
      className="container clearfix"
      style={{
        padding: "0px",
        outline: "none",
        margin: "0px auto",
        marginBottom: "0px",
      }}
    >
      {" "}
      <header
        id="page-header"
        style={{
          margin: "0px",
          padding: "0px",
          outline: "none",
          marginBottom: "40px",
        }}
      >
        <h1
          id="page-title"
          style={{
            margin: "0px",
            outline: "none",
            marginBottom: "15px",
            fontFamily:
              'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", sans-serif',
            fontWeight: 300,
            fontSize: "2.333em",
            lineHeight: "1.214em",
            padding: "10px 0px",
            borderBottom: "1px solid rgb(229, 229, 229)",
          }}
        >
          {children}
        </h1>{" "}
      </header>{" "}
      <section
        style={{
          margin: "0px",
          padding: "0px",
          outline: "none",
          marginBottom: "40px",
        }}
      ></section>
    </section>
  );
}
