import { createContext, useReducer } from "react";
const userContext = createContext();

const initialState = {
  username: "",
  isAuthenticated: false,
  loading: false,
  errorMessage: null,
};
function UserProvider({ children }) {
  function userReducer(state, action) {
    if (action.type === "signin") {
      return {
        ...initialState,
        username: action.payload.username,
        isAuthenticated: true,
      };
    }
    if (action.type === "signout") {
      return {
        ...initialState,
        username: "",
        isAuthenticated: false,
      };
    }
  }
  const [user, dispatch] = useReducer(userReducer, initialState);
  return (
    <userContext.Provider value={{ user, dispatch }}>
      {children}
    </userContext.Provider>
  );
}
export { UserProvider, userContext };
