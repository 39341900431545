import React from "react";
//import Image from "react-bootstrap/Image";
import { FaComputer, FaUserTie } from "react-icons/fa6";
//const url = "".concat(window.REACT_APP_APIBASEURLSANDBOXL_ASAPI, "vat", "/");

const GridCards = () => {
  const servicesData = [
    {
      id: 0,
      icon: <FaUserTie size={40} className="text-primary mb-4" />,
      title: "File your VAT",
      description:
        "You can file your VAT to HMRC directly from your spreadsheet editor. You can manage your VAT fillings with just a few clicks. You do not have to switch to other applications while you are in charge of your data.",
      link: "/vat",
    },
    {
      id: 1,
      icon: <FaUserTie size={40} className="text-primary mb-4" />,
      title: "File your Income Tax Self Assesment",
      description:
        "In 2026 HMRC will mandate sole traders and landlords to file their income using MTD ITSA.",
      link: "/itsa",
    },
    {
      id: 2,
      icon: <FaComputer size={40} className="text-primary mb-4" />,
      title: "Modernize",
      description: "Our New Locator has arrived.",
      link: "/locator",
    },
  ];
  /*
    image: require("./assets/image10.jpg"),
    <Image src={service.image} thumbnail></Image>
    */
  return (
    <>
      <div className="container">
        <div className="py-5">
          <div className="row row-cols-8 g-4">
            {servicesData.map((service, id) => (
              <div
                key={id}
                className={`col-lg-6 col-sm-4  fadeInUp`}
                data-wow-delay={`${0.1 + { id } * 0.2}s`}
              >
                <div className="service-item rounded pt-3">
                  <div className="p-4">
                    <h5>{service.title}</h5>
                    <p>{service.description}</p>

                    <a href={service.link}>read more</a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default GridCards;
