import React from "react";

export default function PrivacyPolicy() {
  return (
    <>
      <section
        id="content"
        className="container clearfix"
        style={{
          padding: "0px",
          outline: "none",
          margin: "0px auto",
          width: "940px",
          marginBottom: "0px",
          minHeight: "996px",
        }}
      >
        {" "}
        <header
          id="page-header"
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "40px",
          }}
        >
          {" "}
          <h1
            id="page-title"
            style={{
              margin: "0px",
              outline: "none",
              marginBottom: "15px",
              fontFamily:
                'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", sans-serif',
              fontWeight: 300,
              fontSize: "2.333em",
              lineHeight: "1.214em",
              padding: "10px 0px",
              borderBottom: "1px solid rgb(229, 229, 229)",
            }}
          >
            Privacy Policy
          </h1>{" "}
        </header>{" "}
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            What is this Privacy Policy for?{" "}
          </strong>
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          This privacy policy is for this website www.attikas.com and served by
          Attika Solutions Ltd , a company registered in England under company
          number 6568821 with VAT number: 932 4845 15 and its registered office
          at 29 Cooke Rise, Warfield, Bracknell, Berkshire, RG42 2QN, United
          Kingdom and governs the privacy of its users who choose to use it.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          The policy sets out the different areas where user privacy is
          concerned and outlines the obligations & requirements of the users,
          the website and website owners. Furthermore the way this website
          processes, stores and protects user data and information will also be
          detailed within this policy.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            The Website
          </strong>
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          This website and it's owners take a proactive approach to user privacy
          and ensure the necessary steps are taken to protect the privacy of its
          users throughout their visiting experience. This website comply's to
          all UK national laws and requirements for user privacy.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Use of Cookies
          </strong>
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          This website uses cookies to better the users experience while
          visiting the website. Where applicable this website uses a cookie
          control system allowing the user on their first visit to the website
          to allow or disallow the use of cookies on their computer / device.
          This complies with recent legislation requirements for website's to
          obtain explicit consent from users before leaving behind or reading
          files such as cookies on a users computer / device.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          Cookies are small files saved to the users computers hard drive that
          track, save and store information about the users interactions and
          usage of the website. This allows the website, through it's server to
          provide the users with a tailored experience within this website.
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          Users are advised that if they wish to deny the use and saving of
          cookies from this website on to their computers hard drive they should
          take necessary steps within their web browsers security settings to
          block all cookies from this website and it's external serving vendors.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          This website uses tracking software to monitor it's visitors to better
          understand how they use it. This software is provided by Google
          Analytics which uses cookies to track visitor usage. The software will
          save a cookie to your computer's hard drive in order to track and
          monitor your engagement and usage of the website but will not store,
          save or collect personal information. You can read Google's privacy
          policy here for further information [
          http://www.google.com/privacy.html ].
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          Other cookies may be stored to your computers hard drive by external
          vendors when this website uses referral programs, sponsored links or
          adverts. Such cookies are used for conversion and referral tracking
          and typically expire after 30 days, though some may take longer. No
          personal information is stored, saved or collected.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Contact & Communication
          </strong>
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          Users contacting this website and/or it's owners do so at their own
          discretion and provide any such personal details requested at their
          own risk. Your personal information is kept private and stored
          securely until a time it is no longer required or has no use, as
          detailed in the Data Protection Act 1998. Every effort has been made
          to ensure a safe and secure form to email submission process but
          advise users using such form to email processes that they do so at
          their own risk.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          This website and it's owners use any information submitted to provide
          you with further information about the products / services they offer
          or to assist you in answering any questions or queries you may have
          submitted. This includes using your details to subscribe you to any
          email newsletter program the website operates but only if this was
          made clear to you and your express permission was granted when
          submitting any form to email process. Or whereby you the consumer have
          previously purchased from or enquired about purchasing from the
          company a product or service that the email newsletter relates to.
          This is by no means an entire list of your user rights in regard to
          receiving email marketing material. Your details are not passed on to
          any third parties.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Email Newsletter
          </strong>
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          This website operates an email newsletter program, used to inform
          subscribers about products and services supplied by this website.
          Users can subscribe through an online automated process should they
          wish to do so but do so at their own discretion. Some subscriptions
          may be manually processed through prior written agreement with the
          user.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          Subscriptions are taken in compliance with UK Spam Laws detailed in
          the Privacy and Electronic Communications Regulations 2003. All
          personal details relating to subscriptions are held securely and in
          accordance with the Data Protection Act 1998. No personal details are
          passed on to third parties nor shared with companies / people outside
          of the company that operates this website. Under the Data Protection
          Act 1998 you may request a copy of personal information held about you
          by this website's email newsletter program. A small fee will be
          payable. If you would like a copy of the information held on you
          please write to the business address at the bottom of this policy.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          Email marketing campaigns published by this website or it's owners may
          contain tracking facilities within the actual email. Subscriber
          activity is tracked and stored in a database for future analysis and
          evaluation. Such tracked activity may include; the opening of emails,
          forwarding of emails, the clicking of links within the email content,
          times, dates and frequency of activity [this is by no far a
          comprehensive list].
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          This information is used to refine future email campaigns and supply
          the user with more relevant content based around their activity.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          In compliance with UK Spam Laws and the Privacy and Electronic
          Communications Regulations 2003 subscribers are given the opportunity
          to un-subscribe at any time through an automated system. This process
          is detailed at the footer of each email campaign. If an automated
          un-subscription system is unavailable clear instructions on how to
          un-subscribe will by detailed instead.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            External Links
          </strong>
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          Although this website only looks to include quality, safe and relevant
          external links users should always adopt a policy of caution before
          clicking any external web links mentioned throughout this website.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          The owners of this website cannot guarantee or verify the contents of
          any externally linked website despite their best efforts. Users should
          therefore note they click on external links at their own risk and this
          website and it's owners cannot be held liable for any damages or
          implications caused by visiting any external links mentioned.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Adverts and Sponsored Links
          </strong>
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          This website may contain sponsored links and adverts. These will
          typically be served through our advertising partners, to whom may have
          detailed privacy policies relating directly to the adverts they serve.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          Clicking on any such adverts will send you to the advertisers website
          through a referral program which may use cookies and will tracks the
          number of referrals sent from this website. This may include the use
          of cookies which may in turn be saved on your computers hard drive.
          Users should therefore note they click on sponsored external links at
          their own risk and this website and it's owners cannot be held liable
          for any damages or implications caused by visiting any external links
          mentioned.
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Contact
          </strong>
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          If you have any questions or comments about the Attika Solutions Ltd
          Privacy Policy please contact us via email or write to us at:
        </p>
        <p
          className="email"
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Email:
          </strong>{" "}
          <a
            href="mailto:info@attikas.com"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              textDecoration: "none",
              transition:
                "color 0.2s, background-color 0.2s, border 0.2s, opacity 0.2s ease-in-out",
              color: "rgb(72, 123, 206)",
            }}
          >
            info@attikas.com
          </a>
        </p>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
          }}
        >
          <strong style={{ margin: "0px", padding: "0px", outline: "none" }}>
            Mail:
          </strong>
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          Attika Solutions Ltd
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          c/o Data Protection Officer
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          29 Cooke Rise
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          Warfield
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          Bracknell, RG42 2QN
          <br style={{ margin: "0px", padding: "0px", outline: "none" }} />
          United Kingdom
        </p>{" "}
      </section>
    </>
  );
}
