import React from "react";

export default function AttikaSystem() {
  return (
    <>
      <section id="content" className="container clearfix">
        <header
          id="page-header"
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "40px",
          }}
        >
          <h1
            id="page-title"
            style={{
              margin: "0px",
              outline: "none",
              marginBottom: "15px",
              fontFamily:
                'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", sans-serif',
              fontWeight: 300,
              fontSize: "2.333em",
              lineHeight: "1.214em",
              padding: "10px 0px",
              borderBottom: "1px solid rgb(229, 229, 229)",
            }}
          >
            Attika® System
          </h1>
        </header>
        <hr
          style={{
            padding: "0px",
            outline: "none",
            border: "none",
            margin: "40px 0px",
            backgroundColor: "rgb(229, 229, 229)",
            color: "rgb(229, 229, 229)",
            height: "1px",
            clear: "both",
          }}
        />
        <h1
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "15px",
            fontFamily:
              'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", "Open Sans", sans-serif',
            fontWeight: 300,
            fontSize: "2.333em",
            lineHeight: "1.214em",
          }}
        >
          Office Automation
        </h1>
        <p
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            marginBottom: "20px",
            marginTop: "30px",
          }}
        >
          <img
            height={200}
            width={280}
            alt="Data Collection"
            src="https://www.attikas.com/slider/1/image2.jpg"
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              display: "block",
              border: "1px solid rgb(221, 221, 221)",
              marginRight: "160px",
            }}
          />
          Automate your tax filling to HMRC:
        </p>
        VAT
        <ul
          className="circle_indent"
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            boxSizing: "border-box",
            listStyle: "inside disc",
            paddingLeft: "70px",
            marginLeft: "400px",
          }}
        >
          {" "}
          <li
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              listStyleImage:
                'url("https://www.attikas.com/wp-content/themes/finesse/images/bullets-and-arrows/circle.png")',
              paddingLeft: "0px",
            }}
          >
            Utilise the easiness of spreadsheet accounting
          </li>{" "}
          <li
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              listStyleImage:
                'url("https://www.attikas.com/wp-content/themes/finesse/images/bullets-and-arrows/circle.png")',
              paddingLeft: "0px",
            }}
          >
            File your VAT to HMRC
          </li>{" "}
          <li
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              listStyleImage:
                'url("https://www.attikas.com/wp-content/themes/finesse/images/bullets-and-arrows/circle.png")',
              paddingLeft: "0px",
            }}
          >
            Works with all popular Browser or Desktop spreadsheets
          </li>{" "}
          <li
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              listStyleImage:
                'url("https://www.attikas.com/wp-content/themes/finesse/images/bullets-and-arrows/circle.png")',
              paddingLeft: "0px",
            }}
          >
            All major browsers are supported
          </li>{" "}
          <li
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              listStyleImage:
                'url("https://www.attikas.com/wp-content/themes/finesse/images/bullets-and-arrows/circle.png")',
              paddingLeft: "0px",
            }}
          >
            Machine independence works in Browser, iPhone, Android phones, iPad
          </li>{" "}
          <li
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              listStyleImage:
                'url("https://www.attikas.com/wp-content/themes/finesse/images/bullets-and-arrows/circle.png")',
              paddingLeft: "0px",
            }}
          >
            <b style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Free for the first year for Startup Companies for up to three
              users
            </b>
          </li>{" "}
          <li
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              listStyleImage:
                'url("https://www.attikas.com/wp-content/themes/finesse/images/bullets-and-arrows/circle.png")',
              paddingLeft: "0px",
            }}
          >
            <b style={{ margin: "0px", padding: "0px", outline: "none" }}>
              Free for three months for established for up to three users
            </b>
          </li>{" "}
          <li
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              listStyleImage:
                'url("https://www.attikas.com/wp-content/themes/finesse/images/bullets-and-arrows/circle.png")',
              paddingLeft: "0px",
            }}
          >
            Accountants are welcome
          </li>
        </ul>
        Income Tax Self Assessment coming soon
        <ul
          className="circle_indent"
          style={{
            margin: "0px",
            padding: "0px",
            outline: "none",
            boxSizing: "border-box",
            listStyle: "inside disc",
            paddingLeft: "70px",
            marginLeft: "400px",
          }}
        >
          {" "}
          <li
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              listStyleImage:
                'url("https://www.attikas.com/wp-content/themes/finesse/images/bullets-and-arrows/circle.png")',
              paddingLeft: "0px",
            }}
          >
            Utilise the easiness of spreadsheet accounting
          </li>{" "}
          <li
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              listStyleImage:
                'url("https://www.attikas.com/wp-content/themes/finesse/images/bullets-and-arrows/circle.png")',
              paddingLeft: "0px",
            }}
          >
            File your Income Tax Self Assessment to HMRC
          </li>{" "}
          <li
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              listStyleImage:
                'url("https://www.attikas.com/wp-content/themes/finesse/images/bullets-and-arrows/circle.png")',
              paddingLeft: "0px",
            }}
          >
            Works with all popular Browser or Desktop spreadsheets
          </li>{" "}
          <li
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              listStyleImage:
                'url("https://www.attikas.com/wp-content/themes/finesse/images/bullets-and-arrows/circle.png")',
              paddingLeft: "0px",
            }}
          >
            All major browsers are supported
          </li>{" "}
          <li
            style={{
              margin: "0px",
              padding: "0px",
              outline: "none",
              listStyleImage:
                'url("https://www.attikas.com/wp-content/themes/finesse/images/bullets-and-arrows/circle.png")',
              paddingLeft: "0px",
            }}
          >
            Machine independence works in Browser, iPhone, Android phones, iPad
          </li>
        </ul>
      </section>
    </>
  );
}
