import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function HTabs() {
  const [key, setKey] = useState("Company");

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
      fill
    >
      <Tab eventKey="Company" title="Company">
        Tab content for Company
      </Tab>
      <Tab eventKey="Accountants" title="Accountants">
        Tab content for Accountants
      </Tab>
    </Tabs>
  );
}

export default HTabs;
